<template>
  <div id="app">
    <BrowserWarning />
    <!-- <BrowserWarning v-if="isWeChatBrowser" /> -->
    <component :is="currentView" />
  </div>
</template>

<script>
import { isMobile } from './utils/device';
import MobileView from './views/MobileView.vue';
import PCView from './views/PCView.vue';

export default {
  name: 'App',
  components: {
    MobileView,
    PCView
  },
  data() {
    return {
      currentView: isMobile() ? 'MobileView' : 'PCView'
    };
  }
};
</script>
<style lang="scss" scoped>
</style>
