<template>
  <div class="pcBox">
    <div class="mainbox">
      <div class="pcheader">
        <div class="logo_home">
          <img src="./../assets/title.png" alt="" />
        </div>
<!--        <div class="pc-code">
          <div class="img">
            <img src="./../assets/code.png" alt="" />
          </div>
          <div class="codetext">扫一扫 立刻下载</div>
        </div>-->
      </div>
      <div class="pcMain">
        <img src="./../assets/all.png" alt="" />
      </div>
      <div class="pcFooter">
        <p>
          <a
            rel="noopener noreferrer"
            class="link-2hia"
            href="https://www.12377.cn/"
            target="_blank"
          >
            网上有害信息举报专区
          </a>
          <a
            rel="noopener noreferrer"
            class="link-2hia"
            href="http://jbts.mct.gov.cn/"
            target="_blank"
          >
            12318全国文化市场举报网站
          </a>
        </p>
        <p>
          <a
            class="link-2hia"
            rel="noopener noreferrer"
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >
            粤ICP备18114502号
<!--            {{ beianNumber }}-->
          </a>
          <a
            class="link-2hia"
            target="_blank"
            rel="noopener noreferrer"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >
            工业和信息化部备案管理系统网站
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PCView",
  data() {
    return {
      beianNumber: '粤ICP备18114502号' // 默认备案号
    }
  },
  mounted() {
    // 获取当前域名
    const domain = window.location.hostname;

    // 配置域名与备案号对应关系
    const beianConfig = {
      'mihuachat.com': '粤ICP备18114502号',
      'mihuacn.com': '粤ICP备18114502号',
      // 添加更多域名配置...
    };

    // 更新备案号
    if (beianConfig[domain]) {
      this.beianNumber = beianConfig[domain];
    }
  }
};
</script>
<style lang="scss" scoped>
.pcBox {
  width: 100vw;
  background: no-repeat 50% url(./../assets/bg.png);
  .mainbox {
    width: 86vw;
    margin: 0 auto;
    .pcheader {
      padding: 22px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .logo_home {
        width: 216px;
        height: 36px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .pc-code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .img {
          width: 124px;
          height: 124px;
          background-color: #fff;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .codetext {
          font-size: 12px;
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.87px;
          text-shadow: 0 0 8px #ff1080;
          text-align: center;
        }
      }
    }
    .pcMain {
      width: 100%;
      margin-top: 46px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .pcFooter {
      margin-top: 170px;
      padding-bottom: 50px;
      p {
        font-size: 12px;
        text-align: center;
        color: #fff;
        line-height: 16px;
        margin-bottom: 10px;
        .link-2hia,
        .text-2uDy {
          padding: 0 4px;
          color: #fff;
          opacity: 0.5;
          background: hsla(0, 0%, 100%, 0);
        }
        .policeImg-A9cQ {
          line-height: 20px;
          height: 20px;
          padding-top: 5px;
          margin-bottom: -3px;
        }
      }
    }
  }
}
</style>
