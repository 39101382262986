<template>
  <div class="mbBox">
    <BrowserWarning v-if="showModel" v-model="showModel" />
    <div class="mbHeader">
      <div class="logo"></div>
      <div class="download" @click="downLoadApp">下载APP</div>
    </div>
    <div class="mbMain">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div>
            <p class="desc-Fm7x">附近推荐</p>
            <p class="title-2wvz">高颜值</p>
          </div>
          <div class="imgWrap img0"></div>
        </div>
        <div class="swiper-slide">
          <div>
            <p class="desc-Fm7x">聊天消息</p>
            <p class="title-2wvz">回复超快</p>
          </div>
          <div class="imgWrap img1"></div>
        </div>
        <div class="swiper-slide">
          <div>
            <p class="desc-Fm7x">真人认证</p>
            <p class="title-2wvz">超真实</p>
          </div>
          <div class="imgWrap img2"></div>
        </div>
      </div>
    </div>
    <div class="mbFooter">
      <p class="copyright">桔子州网络版权所有  @深圳桔子州网络科技有限公司</p>
    </div>
  </div>
</template>

<script>
import { isWeChatBrowser } from "./../utils/device";
import BrowserWarning from "./../components/BrowserWarning.vue"
export default {
  name: "MobileView",
  data() {
    return {
      isWeChatBrowser: isWeChatBrowser(),
      showModel: isWeChatBrowser(),
    };
  },
  components: {
    BrowserWarning
  },
  methods: {
    downLoadApp() {
      if(this.isWeChatBrowser){
        this.showModel = true
      }
      // 设置下载链接
      // const downloadLink = "https://example.com/path/to/app/download";
      // // 创建一个隐藏的<a>元素
      // const link = document.createElement("a");
      // link.href = downloadLink;
      // link.setAttribute("download", "");
      // link.style.display = "none";
      // document.body.appendChild(link);
      // // 模拟点击事件
      // link.click();
      // // 移除隐藏的<a>元素
      // document.body.removeChild(link);
    },
  },
};
</script>
<style lang="scss" scoped>
.mbBox {
  width: 100vw;
  height: 100vh;
  background-color: #ff367d;
  .mbHeader {
    background: hsla(0, 0%, 100%, 0.15);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    height: 17.6vw;
    // background: #ff367d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    max-width: 640px;
    .logo {
      margin-left: 4.266667vw;
      width: 42.666667vw;
      height: 7.466667vw;
      background: url(./../assets/title0.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .download {
      margin-right: 3.733333vw;
      height: 10.666667vw;
      line-height: 10.666667vw;
      width: 21.333333vw;
      background: #fff;
      border-radius: 5.333333vw;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ff367d;
      letter-spacing: 0;
    }
  }
  .mbMain {
    padding-top: 26vw;
    perspective: 1200px;
    min-height: 500px;
    background-color: #ff367d;
    .swiper-slide {
      transform-style: preserve-3d;
      .desc-Fm7x {
        font-size: 14px;
        color: #fff;
        line-height: 5.333333vw;
        text-align: center;
        margin: 0;
      }
      .title-2wvz {
        font-size: 28px;
        color: #fff;
        text-align: center;
        line-height: 10.666667vw;
        margin-top: 0.8vw;
        margin-bottom: 5.333333vw;
        font-weight: 500;
      }
      .imgWrap {
        display: block;
        width: 72vw;
        height: 145.333333vw;
        margin: 0 auto;
      }
      .imgWrap.img0 {
        background: 50% / cover no-repeat url(./../assets/img1.png);
      }
      .imgWrap.img1 {
        background: 50% / cover no-repeat url(./../assets/img2.png);
      }
      .imgWrap.img2 {
        background: 50% / cover no-repeat url(./../assets/img3.png);
      }
    }
  }
  .mbFooter {
    background-color: #ff367d;
    .copyright {
      opacity: 0.6;
      font-size: 9px;
      color: #fff;
      text-align: center;
      padding-top: 5.866667vw;
      padding-bottom: 3.2vw;
    }
  }
}
</style>
