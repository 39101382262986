<template>
  <div v-if="showWarning" class="bodyImg" @click="hideImage">
    <img src="./../assets/browser-warning.png" width="100%" alt="请在浏览器中打开此页面" />
  </div>
</template>

<script>
export default {
  name:"BrowserWarning",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(val){
        this.showWarning = val
      },
      immediate: true
    }
  },
  data() {
    return {
      showWarning: false, // 控制图片显示的状态
    };
  },
  methods: {
    hideImage() {
      this.showWarning = false; // 隐藏图片
      this.$emit("input", false)
    },
  },
};
</script>

<style lang="scss" scoped>
.bodyImg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 666;
  padding: 20px;
}
</style>
